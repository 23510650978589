import { AutoComplete, Button, Flex, Form, Input, Select, Space, Tooltip, Typography } from 'antd';
import { PermissionType, spaceAccessTypes, SpacePermissionConfig } from 'shared/authfishConfig';
import { DeleteOutlined, InfoCircleOutlined, TeamOutlined, UserOutlined } from '@ant-design/icons';
import React, { useMemo } from 'react';
import styles from './PermissionItemForm.module.scss';
import User from 'shared/User';
import uniq from 'lodash/uniq';

function PermissionItemForm({
  loggedInUsers,
  permissionFieldName,
  spaceFieldName,
  onDelete
}: {
  loggedInUsers: User[];
  permissionFieldName: number;
  spaceFieldName: number;
  onDelete: () => unknown;
}) {
  const { setFieldValue } = Form.useFormInstance();
  const isFix = Form.useWatch([
    'spaces',
    spaceFieldName,
    'permissions',
    permissionFieldName,
    'is_fix'
  ]);

  const roleValue = Form.useWatch([
    'spaces',
    spaceFieldName,
    'permissions',
    permissionFieldName,
    'role'
  ]);

  const roleInputSuggestions = useMemo(
    () =>
      [
        {
          label: (
            <Tooltip title="The suggestions are based on the roles of the logged-in users. You can also type the role directly.">
              <Typography.Text type="secondary">
                <Space>
                  <span>Roles</span>
                  <InfoCircleOutlined />
                </Space>
              </Typography.Text>
            </Tooltip>
          ),
          permissionType: 'role' as const,
          fieldValue: '',
          options: uniq(loggedInUsers.flatMap(({ roles }) => roles))
            .filter((role) => !roleValue || role.toLowerCase().includes(roleValue.toLowerCase()))
            .map((role) => ({
              label: (
                <Space>
                  <TeamOutlined />
                  <span>{role}</span>
                </Space>
              ),
              fieldValue: role,
              value: `role.${role}`, // prefix is required to be unique for both roles and users
              permissionType: 'role' satisfies PermissionType
            }))
        },
        {
          label: (
            <Tooltip title="The suggestions are based on the logged-in users. You can also type the username directly.">
              <Typography.Text type="secondary">
                <Space>
                  <span>Users</span>
                  <InfoCircleOutlined />
                </Space>
              </Typography.Text>
            </Tooltip>
          ),
          permissionType: 'user' as const,
          fieldValue: '',
          options: uniq(loggedInUsers.map(({ username }) => username))
            .filter(
              (username) => !roleValue || username.toLowerCase().includes(roleValue.toLowerCase())
            )
            .map((username) => ({
              label: (
                <Space>
                  <UserOutlined />
                  <span>{username}</span>
                </Space>
              ),
              fieldValue: username,
              value: `user.${username}`, // prefix is required to be unique for both roles and users
              permissionType: 'user' satisfies PermissionType
            }))
        }
      ].filter(({ options }) => options.length),
    [loggedInUsers, roleValue]
  );

  return (
    <Flex className={styles.permissionRow} gap={8}>
      <Form.Item className={styles.inputButtonFormItem} name={[permissionFieldName, 'type']}>
        <PermissionTypeToggle disabled={isFix} />
      </Form.Item>
      <Form.Item className={styles.permissionFormItem} name={[permissionFieldName, 'role']}>
        <AutoComplete
          disabled={isFix}
          options={roleInputSuggestions}
          onSelect={(
            _,
            option: {
              permissionType: PermissionType;
              fieldValue: string;
            }
          ) => {
            setFieldValue(
              ['spaces', spaceFieldName, 'permissions', permissionFieldName, 'role'],
              option.fieldValue
            );
            setFieldValue(
              ['spaces', spaceFieldName, 'permissions', permissionFieldName, 'type'],
              option.permissionType
            );
          }}
        >
          <Input
            suffix={
              <Form.Item
                className={styles.inputButtonFormItem}
                name={[permissionFieldName, 'use_regex']}
              >
                <RegexToggle disabled={isFix} />
              </Form.Item>
            }
            disabled={isFix}
            placeholder="*"
          />
        </AutoComplete>
      </Form.Item>
      <Form.Item className={styles.permissionFormItem} name={[permissionFieldName, 'access']}>
        <Select
          size="middle"
          disabled={isFix}
          options={spaceAccessTypes.map((type) => ({
            label: getAccessTypeLabel(type),
            value: type
          }))}
        />
      </Form.Item>
      <Button
        icon={<DeleteOutlined />}
        color="danger"
        variant="text"
        onClick={onDelete}
        disabled={isFix}
      />
    </Flex>
  );
}

const PermissionTypeToggle = ({
  value,
  disabled,
  onChange
}: Partial<{
  disabled: boolean;
  value: PermissionType;
  onChange: (type: PermissionType) => void;
}>) => {
  switch (value) {
    case 'user':
      return (
        <Tooltip
          title={
            !disabled ? 'Permission for a single user. Click to switch to role permission.' : null
          }
        >
          <Button disabled={disabled} icon={<UserOutlined />} onClick={() => onChange?.('role')} />
        </Tooltip>
      );
    case 'role':
      return (
        <Tooltip
          title={!disabled ? 'Permission for a role. Click to switch to user permission.' : null}
        >
          <Button disabled={disabled} icon={<TeamOutlined />} onClick={() => onChange?.('user')} />
        </Tooltip>
      );
    default:
      return null;
  }
};

const RegexToggle = ({
  value,
  disabled,
  onChange
}: Partial<{
  disabled: boolean;
  value: boolean;
  onChange: (useRegex: boolean) => void;
}>) => {
  return (
    <Tooltip title={!disabled ? 'Enable regex matching' : null}>
      <Button
        className={styles.inputButton}
        disabled={disabled}
        onClick={() => onChange?.(!value)}
        size="small"
        color={value ? 'primary' : 'default'}
        variant={value ? 'filled' : 'text'}
      >
        .*
      </Button>
    </Tooltip>
  );
};

function getAccessTypeLabel(accessType: SpacePermissionConfig['access']) {
  switch (accessType) {
    case 'ro':
      return 'Read Only';
    case 'rw':
      return 'Read Write';
    case 'admin':
      return 'Admin';
  }
}

export default PermissionItemForm;
