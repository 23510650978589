import React from 'react';
import { Flex, Typography } from 'antd';
import clsx from 'clsx';

import defaultBranding from '../../lib/defaultBranding';
import { BrandingProps } from '../../types/afConfig';

import styles from './AppPageHeader.module.scss';
import Logo from './Logo';

function AppPageHeader({ branding }: { branding?: BrandingProps }) {
  return (
    <Flex align="center" gap={24} style={{ height: '100%' }}>
      <div
        data-testid="custom-head-snippet"
        dangerouslySetInnerHTML={{
          __html: branding?.custom_head_snippet || ''
        }}
      />

      <a href="/">
        <Flex align="center" gap={16}>
          <Logo branding={branding} className={clsx(styles.logo)} />
          <Typography.Title level={3} className={styles.title} style={{ margin: 0 }}>
            {branding?.title || defaultBranding.title}
          </Typography.Title>
        </Flex>
      </a>
    </Flex>
  );
}

export default AppPageHeader;
