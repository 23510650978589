import React from 'react';
import defaultBranding from '../../lib/defaultBranding';
import { BrandingProps } from '../../types/afConfig';

interface LogoProps {
  className?: string;
  branding?: BrandingProps;
}

function Logo({className, branding}: LogoProps) {
  return (
    <img
      className={className}
      src={branding?.logo_url || defaultBranding.logo_url}
      alt={'Logo'}
    />
  );
}

export default Logo;
