import React from 'react';
import { Menu } from 'antd';
import { LogoutOutlined, UserOutlined } from '@ant-design/icons';

import type User from 'shared/User';
import getMenuItems, { labelMapping } from './helpers/getMenuItems';
import iFramedMode from './helpers/iFramedMode';

import styles from '../../pages/config/ConfigPage.module.scss';
import afLogoutRedirect from './helpers/afLogoutRedirect';

interface UserMenuProps {
  user: User | null;
}

function userDataStr(user: User | null) {
  if (!user) return 'anonymous';
  const userName = user.name || user.username;
  const systemRole = user.system_role || 'user';
  return `${userName} (${systemRole})`;
}

const UserMenu = ({ user }: UserMenuProps) => {
  if (iFramedMode) return <></>;

  const userMenuItems: labelMapping[] = [
    { category: userDataStr(user), icon: <UserOutlined />, isDisabled: true },
    { category: 'Logout', icon: <LogoutOutlined /> }
  ];

  const onItemClickHandler = (label: string) => {
    if (label === 'Logout') afLogoutRedirect();
  };

  return (
    <Menu
      theme="light"
      mode="inline"
      items={getMenuItems(userMenuItems, undefined, onItemClickHandler)}
      className={styles.UserMenu}
    />
  );
};
export default UserMenu;
