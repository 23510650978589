import React, { Dispatch, useEffect, useRef, useState } from 'react';
import {
  App as AntdApp,
  Button,
  Divider,
  Flex,
  Form,
  Input,
  InputRef,
  Spin,
  Typography
} from 'antd';
import { MessageInstance } from 'antd/lib/message/interface';
import clsx from 'clsx';

import { LoginFormFieldType } from '../types/FormFields';
import handleLogin, { authEndpoint, handleLoginResponse } from '../api/handleLogin';
import SubmitButton from './formElements/SubmitButton';
import styles from './LoginForm.module.scss';
import showError from './formElements/utilities/showError';
import validationRules from './formElements/utilities/validationRules';
import afLoginRedirect, { redirectMsg } from './config/helpers/afLoginRedirect';
import logger from '../logger';
import { LoginOutlined } from '@ant-design/icons';
import OidcIcon from './icons/OidcIcon';
import SamlIcon from './icons/SamlIcon';

type SetLoadingCallable = Dispatch<any>;

function redirectToURL(
  url: string,
  setLoadingState?: SetLoadingCallable,
  val?: any,
  messageInstance?: MessageInstance,
  msgAddon?: string
) {
  if (messageInstance) {
    messageInstance.success(msgAddon ? `Redirecting to ${msgAddon}` : redirectMsg).then();
  }
  if (setLoadingState) setLoadingState(val);
  logger.debug(`redirecting to ${url}`);
  window.location.href = url;
}

type authTypesLoginRedirect = 'saml' | 'oidc';

interface LoginFormProps {
  local_enabled?: boolean;
  ldap_enabled?: boolean;
  saml_url?: string;
  oidc_enabled?: boolean;
  className?: string;
}

const LoginForm = ({
  local_enabled = true,
  ldap_enabled = false,
  saml_url = undefined,
  oidc_enabled = false,
  className
}: LoginFormProps) => {
  const [showForm, setShowForm] = useState<boolean>(local_enabled || ldap_enabled);

  // STATE, REFS & EFFECTS
  const [loading, setLoading] = useState<boolean>(false);
  const [shake, setShake] = useState<boolean>(false);

  const { message } = AntdApp.useApp();

  const usernameInput = useRef<InputRef>(null);

  // this is needed to remove the loading spinner when navigating back from oicd or saml
  useEffect(() => {
    window.addEventListener('pageshow', (event) => {
      if (event.persisted) {
        // Page was restored from BFCache
        setLoading(false); // or trigger re-init
      }
    });
  }, []);

  const redirect2gateway = (target: authTypesLoginRedirect) => {
    const redirTarget = target === 'saml' ? (saml_url as string) : authEndpoint(target);
    redirectToURL(redirTarget, setLoading, !loading, message, `${target.toUpperCase()} gateway`);
  };

  // THE FORM
  const [form] = Form.useForm<LoginFormFieldType>();

  const loginInputRules = (fieldLabel: string) => {
    return validationRules(fieldLabel, 'text', true, 'your');
  };

  const processAPIResponse = async ({ success, redirectUrl, msg }: handleLoginResponse) => {
    if (success) {
      // normally can't see it locally though, 2 fast
      afLoginRedirect(redirectUrl as string, message);
    } else {
      if (msg?.toLowerCase().includes('network')) {
        message.error(msg, 10);
        return;
      }
      showError(['password'], msg || 'Unknown error, please try again!', form);
      setShake(true);
      setTimeout(() => setShake(false), 600);
    }
  };

  const onFinish = async (values: LoginFormFieldType) => {
    setLoading(true);
    const resp = await handleLogin(values);
    setLoading(false);
    await processAPIResponse(resp);
  };

  // COMPONENTS & LAYOUT

  return (
    <Spin spinning={loading} wrapperClassName={className}>
      <Flex vertical gap={16} className={styles.container}>
        {!!saml_url && (
          <Button
            onClick={() => redirect2gateway('saml')}
            className={styles.providerButton}
            icon={<SamlIcon />}
          >
            Continue with SAML
          </Button>
        )}

        {!!oidc_enabled && (
          <Button
            onClick={() => redirect2gateway('oidc')}
            className={styles.providerButton}
            icon={<OidcIcon />}
          >
            Continue with OIDC
          </Button>
        )}

        {(oidc_enabled || saml_url) && (
          <Divider className={styles.divider}>or continue with</Divider>
        )}

        {showForm ? (
          <Form
            layout="vertical"
            form={form}
            name="login"
            className={clsx(styles.login_form, shake ? ' shake' : '')}
            onFinish={onFinish}
          >
            <Flex vertical gap={16}>
              <Form.Item<LoginFormFieldType>
                name="username"
                required={false}
                label={<Typography.Text strong={true}>Username</Typography.Text>}
                rules={loginInputRules('username')}
                className={styles.input_item}
              >
                <Input
                  placeholder="Username"
                  name="username"
                  ref={usernameInput}
                  autoComplete="username"
                  autoFocus
                />
              </Form.Item>

              <Form.Item<LoginFormFieldType>
                required={false}
                label={<Typography.Text strong={true}>Password</Typography.Text>}
                name="password"
                rules={loginInputRules('password')}
                className={styles.input_item}
              >
                <Input.Password placeholder="Password" name="password" autoComplete="off" />
              </Form.Item>

              <SubmitButton
                size="large"
                className={styles.loginButton}
                icon={<LoginOutlined />}
                form={form}
                caption={`Login`}
                dataTestID={'loginButton'}
              />
            </Flex>
          </Form>
        ) : (
          <Button
            icon={<LoginOutlined />}
            color="primary"
            variant="text"
            onClick={() => setShowForm(true)}
          >
            Login as system user
          </Button>
        )}
      </Flex>
    </Spin>
  );
};

export default LoginForm;
