import type { AuthfishConfig } from './authfishConfig';

function serverURL(config: AuthfishConfig): string {
  return `${serverProtocol(config)}://${config.site_url}:${config.port}`;
}

export function serverProtocol(config: AuthfishConfig) {
  return config.https?.enabled ? 'https' : 'http';
}

export function selfURL(config: AuthfishConfig): string {
  return `${serverProtocol(config)}://localhost:${config.port}`;
}

export default serverURL;
