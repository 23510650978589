import { Alert, Button, Card, Flex, Form, Input, List, Typography } from 'antd';
import React from 'react';
import { DeleteOutlined, PlusOutlined, UserOutlined } from '@ant-design/icons';
import styles from './SpacesForm.module.scss';
import { SpaceConfig, SpacePermissionConfig } from 'shared/authfishConfig';
import PermissionItemForm from './PermissionItemForm';
import { createShortId } from 'shared/createShortId';
import User from 'shared/User';

function SpacesForm({ loggedInUsers }: { loggedInUsers: User[] }) {
  const spaces: SpaceConfig[] | undefined = Form.useWatch(['spaces']);
  return (
    <Flex vertical gap={8} className={styles.spacesForm}>
      <Typography.Paragraph className={styles.infoText}>
        Spaces are used to manage permissions and access to resources. You can set which roles have
        access to which spaces and what permissions they have.
      </Typography.Paragraph>
      <Alert
        message={
          <>
            System users automatically have <b>admin</b> permissions for all spaces.
          </>
        }
      />
      <Typography.Title className={styles.header} level={4}>
        Spaces
      </Typography.Title>
      <Form.List name="spaces">
        {(fields, { add, remove }) => (
          <>
            <Flex vertical gap={16}>
              {fields.map((field) => (
                <SpaceForm
                  loggedInUsers={loggedInUsers}
                  onDelete={() => remove(field.name)}
                  spaceFieldName={field.name}
                  key={field.key}
                />
              ))}
            </Flex>
            <Button
              icon={<PlusOutlined />}
              className={styles.addSpaceButton}
              onClick={() =>
                add({
                  name: '',
                  id: createShortId(spaces?.map((space) => space.id) || []),
                  permissions: [
                    {
                      access: 'ro',
                      role: null,
                      type: 'role'
                    }
                  ]
                } satisfies SpaceConfig)
              }
            >
              Add Space
            </Button>
          </>
        )}
      </Form.List>
    </Flex>
  );
}

function SpaceForm({
  loggedInUsers,
  spaceFieldName,
  onDelete
}: {
  loggedInUsers: User[];
  spaceFieldName: number;
  onDelete: () => unknown;
}) {
  const spaceName = Form.useWatch(['spaces', spaceFieldName, 'name']);
  const isDefault = Form.useWatch(['spaces', spaceFieldName, 'is_default']);
  return (
    <Card
      title={
        <Flex justify="space-between" align="center">
          <Typography.Text>{spaceName || 'New Space'}</Typography.Text>
          {!isDefault && (
            <Button
              color="danger"
              variant="text"
              icon={<DeleteOutlined />}
              onClick={onDelete}
            ></Button>
          )}
        </Flex>
      }
    >
      <Form.Item
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        rules={[{ required: true, message: 'Please input space name!' }]}
        label="Name"
        name={[spaceFieldName, 'name']}
      >
        <Input disabled={isDefault} />
      </Form.Item>

      <Form.Item
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        required={true}
        label="Permissions"
        name={[spaceFieldName, 'permissions']}
      >
        <Form.List
          name={[spaceFieldName, 'permissions']}
          rules={[
            {
              validator: (_, value) => (value.length ? Promise.resolve() : Promise.reject()),
              message: 'At least one permission is required'
            }
          ]}
        >
          {(fields, { add, remove }) => (
            <Flex vertical gap={16}>
              <List
                header={
                  <Flex className={styles.permissionRow} gap={8}>
                    <Typography.Text className={styles.permissionFormItem} strong>
                      Role/User
                    </Typography.Text>
                    <Button icon={<UserOutlined />} className={styles.buttonPlaceholder} />
                    <Typography.Text className={styles.permissionFormItem} strong>
                      Access
                    </Typography.Text>
                    <Button icon={<DeleteOutlined />} className={styles.buttonPlaceholder} />
                  </Flex>
                }
                size="small"
                bordered
                dataSource={fields}
                renderItem={(field) => (
                  <List.Item>
                    <PermissionItemForm
                      loggedInUsers={loggedInUsers}
                      onDelete={() => remove(field.name)}
                      spaceFieldName={spaceFieldName}
                      permissionFieldName={field.name}
                    />
                  </List.Item>
                )}
              />
              <Button
                icon={<PlusOutlined />}
                size="small"
                className={styles.addPermissionButton}
                onClick={() =>
                  add({
                    role: null,
                    access: 'ro',
                    type: 'role'
                  } satisfies SpacePermissionConfig)
                }
              >
                Add Permission
              </Button>
            </Flex>
          )}
        </Form.List>
      </Form.Item>
    </Card>
  );
}

export default SpacesForm;
