import { AuthStrategies } from 'shared/authfishConfig';
import { LoginFormFieldType } from '../types/FormFields';
import { APP_LOGIN_PATH } from 'shared/routes';
import logger from '../logger';

export interface handleLoginResponse {
  success: boolean;
  redirectUrl?: string;
  msg?: string;
}

export const authEndpoint = (authType: AuthStrategies) => `/auth/login-${authType}`;

async function handleLogin({
  username,
  password
}: LoginFormFieldType): Promise<handleLoginResponse> {
  function rejectRespNotAuthRelated(err?: unknown): handleLoginResponse {
    // messages used in handleLogin.test.ts
    const msg = err ? `Can't connect to server: ${err}` : 'Please select an auth type';
    return {
      success: false,
      msg: msg
    };
  }

  const endpoint = authEndpoint('local');
  if (!endpoint) return rejectRespNotAuthRelated();
  let loginResp: Response;
  try {
    loginResp = await fetch(endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        username: username,
        password: password
      })
    });
  } catch (err: unknown) {
    return rejectRespNotAuthRelated(err || 'Unknown error');
  }
  logger.debug(`handleLogin: ${loginResp?.url}`);
  if (!loginResp) return rejectRespNotAuthRelated(`!loginResp ${endpoint}`);
  // success should be "/" but this seems a safer approach:
  if (!loginResp.url.includes(APP_LOGIN_PATH)) {
    // return [true, loginResp.url, `Redirecting to ${loginResp.url}`];
    // actually we are a proxy, so the url makes no sense for the user
    return {
      success: true,
      redirectUrl: loginResp.url
    };
  } else {
    return {
      success: false,
      msg: 'Wrong credentials, please try again!'
    };
  }
}

export default handleLogin;
