import { useEffect, useRef, useState } from 'react';
import { HookAPI } from 'antd/lib/modal/useModal';

import { LOGOUT_ROUTE } from 'shared/routes';
import { AuthfishConfig } from 'shared/authfishConfig';
import { AuthfishStaticConfig } from 'shared/authfishStaticConfig';
import type { ProtectedSettings } from 'shared/AuthfishParams';
import type User from 'shared/User';
import afLogoutRedirect from '../components/config/helpers/afLogoutRedirect';
import getConfigHandler, { configSourceData } from '../api/getConfigHandler';
import showErrorModal from '../lib/showErrorModal';

interface ConfigLoaderResult {
  config: AuthfishConfig | null;
  setConfig: (config: AuthfishConfig) => void;
  staticConfig: AuthfishStaticConfig | null;
  requiresOnboarding: string | null;
  user: User | null;
  timestampsAvailable: number[];
  setTimestampsAvailable: (
    timestampsAvailable: number[] | ((prevTimestamps: number[]) => number[])
  ) => void;
  protectedSettings: ProtectedSettings[];
  sourceData: configSourceData | undefined;
  loading: boolean;
  loggedInUsers: User[];
}

const useConfigLoader = (modal: HookAPI): ConfigLoaderResult => {
  const [config, setConfig] = useState<AuthfishConfig | null>(null);
  const [staticConfig, setStaticConfig] = useState<AuthfishStaticConfig | null>(null);
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [timestampsAvailable, setTimestampsAvailable] = useState<number[]>([]);
  const [protectedSettings, setProtectedSettings] = useState<ProtectedSettings[]>([]);
  const [sourceData, setSourceData] = useState<configSourceData | undefined>(undefined);
  const [requiresOnboarding, setRequiresOnboarding] = useState<string | null>(null);
  const [loggedInUsers, setLoggedInUsers] = useState<User[]>([]);

  const hasEffectRun = useRef<boolean>(false);

  useEffect(() => {
    if (hasEffectRun.current) return;
    hasEffectRun.current = true;
    setLoading(true);

    const handleError = (errorMsg: string, permissionsIssue: boolean = false) => {
      const redirectFn = permissionsIssue ? afLogoutRedirect : undefined;
      return showErrorModal(errorMsg, modal, redirectFn);
    };

    getConfigHandler().then((resp) => {
      const {
        config,
        staticConfig,
        requiresOnboarding: requiresOnboardingUpd,
        errorMsg,
        status,
        timestampsAvailable,
        protectedSettings,
        sourceData,
        user,
        loggedInUsers
      } = resp;

      setConfig(config);
      setStaticConfig(staticConfig);
      setLoggedInUsers(loggedInUsers ?? []);
      if (timestampsAvailable) {
        setTimestampsAvailable(timestampsAvailable.map((timestamp) => parseInt(timestamp)));
      }
      if (protectedSettings) setProtectedSettings(protectedSettings);
      if (user) setUser(user);
      setSourceData(sourceData);
      setRequiresOnboarding(requiresOnboardingUpd);

      const redirectURL = status === 401 ? LOGOUT_ROUTE : undefined;
      if (redirectURL) afLogoutRedirect(redirectURL);

      const permissionsIssue = (errorMsg && errorMsg.includes('permission')) || false;
      if (errorMsg) handleError(errorMsg, permissionsIssue);

      setLoading(false);
    });
  }, [modal]);

  return {
    config,
    setConfig,
    staticConfig,
    requiresOnboarding,
    user,
    timestampsAvailable,
    setTimestampsAvailable,
    protectedSettings,
    sourceData,
    loading,
    loggedInUsers
  };
};

export default useConfigLoader;
