import { authTypesLabels, FieldCategories, FormField } from '../types/FormFields';
import { configSchema } from 'shared/authfishConfig';
import generateFormFields from './generateFormFields';

export function getAllKeysFromConfig(maxLevel: number = 2): string[] {
  // level 1 means the top-level key
  const allKeysUsed = generateFormFields(configSchema)
    .map((field) => {
      return field.key.split('.').length <= maxLevel ? field.key : undefined;
    })
    .filter((key) => key !== undefined);
  return Array.from(new Set(allKeysUsed)) as string[];
}

export function keyShouldStartFromByCat(activeLabel: FieldCategories): string[] {
  // we'll return an array of appropriate keys for the FormField
  switch (activeLabel) {
    case 'Backend':
      return ['site_url', 'port', 'https', 'session', 'proxy'];
    case 'Auth':
      return authTypesLabels.flatMap((l) => keyShouldStartFromByCat(l));
    case 'Local':
    case 'LDAP':
    case 'SAML':
    case 'OIDC':
      return [`auth.${activeLabel.toLowerCase()}`];
    case 'Activation key': {
      return [];
    }
    case 'Permissions':
      return [];
    case 'General':
      return [
        'auth.enabled',
        'auth.strategies',
        'public_routes',
        'branding.logo_url',
        'branding.favicon_url',
        'branding.title',
        'branding.subtitle',
        'branding.footer',
        'branding.custom_head_snippet',
        'log_level'
      ];
  }
}

function filterFormFields(formFields: FormField[], activeLabel?: FieldCategories): FormField[] {
  if (!activeLabel) return formFields;
  const keysStart = keyShouldStartFromByCat(activeLabel);
  return formFields.filter((field) => keysStart.some((prefix) => field.key.startsWith(prefix)));
}

export default filterFormFields;
