import React, { memo, Suspense, useEffect, useMemo, useRef, useState } from 'react';
import { App as AntdApp, Flex, Form, FormInstance, Spin } from 'antd';
import { ReloadOutlined, SaveOutlined } from '@ant-design/icons';

import styles from '../../pages/config/ConfigPage.module.scss';

import { AuthfishConfig, configKeysRequiringRestart, configSchema } from 'shared/authfishConfig';
import type { ProtectedSettings } from 'shared/AuthfishParams';
import { FormContext } from './useFormContext';
import generateFormFields from '../../configSchemas/generateFormFields';
import FormItems from './FormItems';
import filterFormFields from '../../configSchemas/filterFormFields';
import { pageTitle } from './helpers/formTexts';
import SubmitButton from '../formElements/SubmitButton';
import ResetButton from '../formElements/ResetButton';
import validateAndSaveConfig, { validateConfigResp } from '../../api/validateAndSaveConfig';
import preValidateFields from './helpers/preValidateFields';
import { configSourceData } from '../../api/getConfigHandler';
import showSaveConfigResult from './helpers/showSaveConfigResult';
import { FieldCategories } from '../../types/FormFields';
import { ActivationKeyManagement } from './customElements';
import DropdownMenu from './DropdownMenu';
import ConfigUploader from './ConfigUploader';
import { isEmpty, merge } from 'lodash';
import { AuthfishStaticConfig } from 'shared/authfishStaticConfig';
import logger from '../../logger';
import SpacesForm from './customElements/SpacesForm/SpacesForm';
import User from 'shared/User';
import { getConfigChanges } from 'shared/getConfigChanges';

const allItemsMapping = (protectedSettings: ProtectedSettings[]) =>
  generateFormFields(configSchema, protectedSettings);

interface headingInfo {
  title?: string;
  instructions?: React.ReactNode;
}

interface configFormProps {
  config: AuthfishConfig;
  staticConfig: AuthfishStaticConfig;
  setConfig: (config: AuthfishConfig) => void;
  activeLabel?: FieldCategories;
  timestampsAvailable?: number[];
  addTimestamp?: (timestamp: number) => void;
  protectedSettings?: ProtectedSettings[];
  configSourceObj?: configSourceData;
  onFinishExtraCallable?: (config: AuthfishConfig) => void | Promise<void>;
  headingInfo?: headingInfo;
  onFormReadyCallable?: (form: FormInstance) => void;
  loggedInUsers: User[];
}

const ConfigForm = ({
  config,
  staticConfig,
  setConfig,
  activeLabel,
  timestampsAvailable = [],
  addTimestamp = (_timestamp: number) => {},
  protectedSettings = [],
  configSourceObj,
  onFinishExtraCallable,
  headingInfo = {},
  onFormReadyCallable,
  loggedInUsers
}: configFormProps) => {
  const [initialValues, setInitialValues] = useState<AuthfishConfig>(config);
  const [loading, setLoading] = useState<boolean>(false);

  const [form] = Form.useForm<AuthfishConfig>();

  const { message, modal } = AntdApp.useApp();

  useEffect(() => {
    if (!onFormReadyCallable) return;
    setTimeout(() => onFormReadyCallable(form), 999);
  }, [form, onFormReadyCallable]);

  // it seems the only way to use the Upload without a Button is implementing an invisible one
  const uploadBtnRef = useRef<HTMLButtonElement>(null);
  const startImportFunc = () => {
    uploadBtnRef.current?.click();
  };

  const onFinish = async (formValues: AuthfishConfig) => {
    if (
      getConfigChanges(
        initialValues,
        merge({}, initialValues, formValues),
        configKeysRequiringRestart
      ).length
    ) {
      const confirmed = modal.confirm({
        title: 'Saving the changes will restart the server. Are you sure you want to continue?',
        content: 'You changed some settings that require a server restart.'
      });
      if (!confirmed) {
        return;
      }
    }

    setLoading(true);
    logger.debug(`onFinish: ${JSON.stringify(formValues)}`);
    // note that in tests when the config has issues we usually don't need API access; see configEncryptedFromFormValues
    const validateResp: validateConfigResp = await validateAndSaveConfig(
      config,
      setConfig,
      addTimestamp,
      formValues,
      configSourceObj
    );
    await showSaveConfigResult(validateResp, configSourceObj, form, message, modal);
    if (onFinishExtraCallable) onFinishExtraCallable(config);
    setLoading(false);
    setInitialValues(config);
  };

  const itemsMapping = filterFormFields(allItemsMapping(protectedSettings), activeLabel);

  const isEmptyConfig = isEmpty(itemsMapping);

  const preValidateCallBack = (
    // setting default values when undefined
    formInstance: FormInstance,
    values: Record<string, any>
  ) => preValidateFields(itemsMapping, formInstance, values);

  const dropDownCommonProps = {
    form,
    config,
    startImportFunc,
    setLoading
  };

  const customContentRender = useMemo(() => {
    switch (activeLabel) {
      case 'Activation key': {
        return (
          <Suspense fallback={<div>Loading...</div>}>
            <ActivationKeyManagement
              configSourceObj={configSourceObj}
              staticConfig={staticConfig}
            />
          </Suspense>
        );
      }
      case 'Permissions':
        return <SpacesForm loggedInUsers={loggedInUsers} />;

      default:
        return null;
    }
  }, [activeLabel, configSourceObj, loggedInUsers, staticConfig]);

  const { title, instructions } = headingInfo;
  const instructionsBlock = useMemo(() => {
    return instructions ? <div className={styles.instructions}>{instructions}</div> : null;
  }, [instructions]);

  const showSaveRow = activeLabel !== 'Activation key';

  return (
    <FormContext.Provider value={form}>
      <Form
        form={form}
        name="config"
        initialValues={config}
        onFinish={onFinish}
        data-testid="configForm"
      >
        <Spin size={'large'} spinning={loading}>
          <h1 style={{ textTransform: 'capitalize' }}>{title || pageTitle(activeLabel)}</h1>
          {instructions && instructionsBlock}

          {!isEmptyConfig && <FormItems itemsMapping={itemsMapping} config={config} />}

          {customContentRender}

          {showSaveRow && (
            <>
              <Form.Item className={'formButtons'}>
                <Flex justify={'space-between'}>
                  <Flex justify={'space-evenly'} className={styles.dropButton}>
                    <ResetButton
                      form={form}
                      icon={<ReloadOutlined />}
                      dataTestID={'resetConfigButton'}
                    />
                    <DropdownMenu
                      {...dropDownCommonProps}
                      type={'reset'}
                      dataTestID={'resetDropDown'}
                      timestampsAvailable={timestampsAvailable}
                    />
                  </Flex>
                  <Flex justify={'space-evenly'} className={styles.dropButton}>
                    <SubmitButton
                      form={form}
                      caption={'Save'}
                      icon={<SaveOutlined />}
                      dataTestID={'saveConfigButton'}
                      preValidateCallBack={preValidateCallBack}
                      ExtraElement={
                        <DropdownMenu
                          {...dropDownCommonProps}
                          type={'save'}
                          dataTestID={'saveDropDown'}
                        />
                      }
                    />
                  </Flex>
                </Flex>
              </Form.Item>

              <ConfigUploader
                form={form}
                uploadBtnRef={uploadBtnRef}
                setLoading={setLoading}
                setConfig={setConfig}
              />
            </>
          )}
        </Spin>
      </Form>
    </FormContext.Provider>
  );
};

export default memo(ConfigForm);
