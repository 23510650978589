import React, { useEffect, useRef, useState } from 'react';
import type { InputRef } from 'antd';
import { Flex, Input, Tag } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

type BaseInputProps = React.InputHTMLAttributes<HTMLInputElement>;

interface TagInputProps extends Omit<BaseInputProps, 'onChange' | 'size'> {
  label?: string;
  value?: string[]; // will be passed from the Form.Item
  onChange?: (value: string[] | undefined) => void; // ...also from the Form.Item
}

const basicTagsWrapperStyle = { marginBottom: '-8px' };

const basicTagStyle = {
  fontSize: 'unset',
  padding: '4px 8px',
  marginBottom: '8px' // matching basicTagsWrapperStyle marginBottom * -1
};

function addLabel(label: string) {
  const newLabel = label ? `new ${label.endsWith('s') ? label.slice(0, -1) : label}` : '';
  return `Add ${newLabel}`;
}

const TagsInput = ({
  label = '',
  value: allTags = [],
  onChange = () => {},
  ...rest
}: TagInputProps) => {
  const [tags, setTags] = useState<string[]>(allTags);

  // input aka "the last tag" state
  const [inputVisible, setInputVisible] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>('');
  const inputRef = useRef<InputRef>(null);
  useEffect(() => {
    if (inputVisible) {
      inputRef.current?.focus();
    }
  }, [inputVisible]);

  const resetInputValue = () => {
    setInputVisible(false);
    setInputValue('');
  };

  const handleClose = (removedTag: string) => {
    const newTags = tags.filter((tag) => tag !== removedTag);
    setTags(newTags);
    onChange(newTags);
  };

  const getAllTags = (valueUpdate: string = inputValue) => {
    // can't just use inputValue here on change since it's like 1 step behind
    return valueUpdate && !tags.includes(valueUpdate) ? [...tags, valueUpdate] : tags;
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setInputValue(newValue);
    onChange(getAllTags(newValue));
  };

  const handleInputConfirm = () => {
    setTags(getAllTags());
    onChange(getAllTags()); // not sure why, but also works without onChange here
    resetInputValue();
  };

  const showInput = () => {
    setInputVisible(true);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      // could use onPressEnter instead
      handleInputConfirm();
    } else if (event.key === 'Escape') {
      resetInputValue();
    }
  };

  // could also add editing on double click but it's hard to figure out
  const { id, ...restProps } = rest;
  const testIDBase = 'TagsInput';

  return (
    <Flex vertical data-testid={testIDBase} {...restProps} style={basicTagsWrapperStyle}>
      <div>
        {tags.map((tag) => {
          return (
            <Tag
              data-testid={`${testIDBase}-tag-${tag}`}
              key={tag}
              closable
              onClose={() => handleClose(tag)}
              style={basicTagStyle}
            >
              {tag}
            </Tag>
          );
        })}
        <Input
          id={id}
          ref={inputRef}
          data-testid={`${testIDBase}-input`}
          type="text"
          // always render input for label and getFieldInstance, but only show it when needed
          style={{ ...basicTagStyle, width: '150px', display: inputVisible ? 'block' : 'none' }}
          value={inputValue}
          onChange={handleInputChange}
          onBlur={handleInputConfirm}
          onKeyDown={(event) => handleKeyDown(event)}
        />
        {!inputVisible && (
          <Tag
            data-testid={`${testIDBase}-addNew`}
            onClick={showInput}
            style={{ ...basicTagStyle, borderStyle: 'dashed', cursor: 'pointer' }}
          >
            <PlusOutlined /> {addLabel(label)}
          </Tag>
        )}
      </div>
    </Flex>
  );
};

export default TagsInput;
