import { z } from 'zod';

import { SESSIONS_DB_FILE_NAME } from './dbPath';
import type { DeepKeys } from './typeUtils';

const LogLevelTypeArray = ['warn', 'info', 'error', 'debug', 'trace'] as const;

export const defaultHttpsKey = 'certs/key.pem';
export const defaultHttpsCert = 'certs/cert.pem';

export const httpsSchema = z
  .object({
    enabled: z.boolean().default(false),
    key: z.string().optional().default(defaultHttpsKey),
    cert: z.string().optional().default(defaultHttpsCert)
  })
  .refine((data) => !data.enabled || (data.key && data.cert), {
    message: 'Key and cert are required when HTTPS is enabled'
  });

const sessionSchema = z.object({
  cookie_name: z.string(),
  secret: z.string(),
  max_age_hours: z.number().int().min(1).default(60),
  persistent_storage: z.boolean().default(true),
  db_path: z.string().optional().nullable().default(SESSIONS_DB_FILE_NAME)
});

const proxySchema = z.object({
  enabled: z.boolean(),
  target: z.string().url()
});

export const authStrategies = ['local', 'saml', 'ldap', 'oidc'] as const;
export type AuthStrategies = (typeof authStrategies)[number];

// system can access config, no one can see ProtectedSettings
export const roles = ['user', 'system'] as const;
export type SystemUserRole = (typeof roles)[number];

export const userSchema = z.object({
  username: z.string(),
  password_hash: z.string(),
  algorithm: z.enum(['sha256', 'sha512']).default('sha512'),
  roles: z.array(z.string().min(1)).default([]),
  system_role: z.enum(roles).default('user')
});

const tokenEndpointAuthMethods = ['client_secret_basic', 'client_secret_post'] as const;

export const defaultTokenEndpointAuthMethods: (typeof tokenEndpointAuthMethods)[number] =
  'client_secret_basic';

export type UserSchema = z.infer<typeof userSchema>;

const localStrategySchema = z.object({
  users: z
    .array(userSchema)
    .min(1)
    .refine((users) => users.some((user) => user.system_role === 'system'), {
      message: 'At least one system user is required'
    })
});

const ldapStrategySchema = z.object({
  url: z.string().url(),
  bindDN: z.string(),
  bindCredentials: z.string(),
  searchBase: z.string(),
  searchFilter: z.string(),
  usernameField: z.string(),
  passwordField: z.string()
});

export const defaultRoleParam = 'Role';

export const samlStrategySchema = z.object({
  entry_point: z.string().url(),
  // protocol: z.string().default('https'), // deprecated, we could infer from entrypoint URL
  issuer: z.string().min(2),
  cert: z.string(),
  logout_callback_url: z.string().url(),
  decryption_pvk: z.string(),
  accepted_clock_skew_ms: z.number().int().min(-1).default(-1),
  usernameParameter: z.string().default('nameID'),
  groupsParameter: z.string().default(defaultRoleParam)
});

export const defaultOIDCScopes = ['openid', 'profile', 'email'];

const keyCloakPathPrefix = '/protocol/openid-connect';

export const defaultLogoutPath = `${keyCloakPathPrefix}/logout`; // default logout path
export const defaultUsernameParam = 'preferred_username'; // depends on library used
export const defaultGroupsParam = 'groups';

const oidcStrategySchema = z.object({
  issuer: z.string().url(),
  callbackURL: z.string().url().optional().nullable().default(null).or(z.undefined()),
  logoutPath: z.string().optional().default(defaultLogoutPath),
  clientID: z.string(),
  clientSecret: z.string(),
  // some platforms use non-standard scopes like
  // https://learn.microsoft.com/en-us/entra/identity-platform/scopes-oidc#openid-connect-scopes
  scope: z.array(z.string()).optional().default(defaultOIDCScopes),
  usernameParameter: z.string().optional().default(defaultUsernameParam), // preferred_username in ROR
  groupsParameter: z.string().optional().default(defaultGroupsParam),
  proxyURL: z.string().url().optional().nullable().default(null).or(z.undefined()),
  authMethod: z.enum(tokenEndpointAuthMethods).optional().default(defaultTokenEndpointAuthMethods)
});

export type OIDCStrategySchema = z.infer<typeof oidcStrategySchema>;

export const authSchema = z.object({
  enabled: z.boolean(),
  strategies: z.array(z.enum(authStrategies)).min(1).default(['local']),
  local: localStrategySchema.optional(),
  ldap: ldapStrategySchema.optional(),
  saml: samlStrategySchema.optional(),
  oidc: oidcStrategySchema.optional()
});

// export type AuthSchema = z.infer<typeof authSchema>;

export function getAuthStrategySchema<T extends AuthStrategies>(
  strategy: T
): (typeof authSchema)['shape'][T] {
  return authSchema.shape[strategy];
}

export const brandingSchema = z.object({
  // logo_url, title, subtitle, footer, custom_head_snippet
  logo_url: z.string().url().optional(), // vs the image stored separately if uploaded directly
  favicon_url: z.string().url().optional(),
  title: z.string(),
  subtitle: z.string(),
  footer: z.string().optional(),
  custom_head_snippet: z.string().optional() // can include stylesheets, <script> etc.
});
export type BrandingSchema = z.infer<typeof brandingSchema>;

export type RouteMatcher = string | RegExp;

const restrictedPaths = ['/auth/', '/login/']; // import them ..?
const restrictedPathMsg = 'The following paths are restricted: ' + restrictedPaths.join(', ');
const isStringNotRestricted = (route: string): boolean => {
  return !restrictedPaths.includes(route);
};

const routeMatcherSchema = z.union([
  z.string().refine(isStringNotRestricted, { message: restrictedPathMsg }),
  z.instanceof(RegExp)
]);

const PublicRoutesSchema = z
  .array(routeMatcherSchema)
  .default([])
  .refine(
    (routes) =>
      !routes.some((route) => typeof route === 'string' && restrictedPaths.includes(route)),
    { message: restrictedPathMsg }
  );

export const spaceAccessTypes = ['ro', 'rw', 'admin'] as const;
export type SpaceAccessType = (typeof spaceAccessTypes)[number];

export const permissionTypes = ['role', 'user'] as const;
export type PermissionType = (typeof permissionTypes)[number];

const spacePermissionSchema = z.object({
  role: z
    .string()
    .nullable()
    .transform((val) => val?.trim().replace(/^\*$/, '') || null), // null is wildcard
  access: z.enum(spaceAccessTypes),
  type: z.enum(permissionTypes),
  use_regex: z.boolean().optional(),
  is_fix: z.boolean().optional()
});

export type SpacePermissionConfig = z.infer<typeof spacePermissionSchema>;

const spaceSchema = z.object({
  id: z.string(),
  name: z.string(),
  permissions: z.array(spacePermissionSchema),
  is_default: z.boolean().optional()
});

export type SpaceConfig = z.infer<typeof spaceSchema>;
export const defaultSpaceId = 'public';

const defaultSpace: SpaceConfig = {
  id: defaultSpaceId, // use fix id in case of migrations
  name: 'Public',
  permissions: [
    {
      role: null,
      access: 'ro',
      is_fix: true,
      type: 'role'
    },
    {
      role: 'admin',
      access: 'admin',
      type: 'role'
    }
  ],
  is_default: true
};

export const configSchema = z.object({
  site_url: z.string().url(),
  port: z.number().int().min(1).max(65535).default(5601),
  https: httpsSchema,
  session: sessionSchema,
  proxy: proxySchema,
  auth: authSchema,
  // new entries to reflect "BlobObject" migration
  public_routes: PublicRoutesSchema,
  branding: brandingSchema.optional().nullable().default(null),
  log_level: z.enum(LogLevelTypeArray).default('info'),
  spaces: z
    .array(spaceSchema)
    .min(1)
    .refine(
      (spaces) =>
        spaces.some(
          ({ is_default, permissions }) =>
            is_default && permissions.some(({ role }) => role === null)
        ),
      {
        message: 'A default space with a wildcard role permission is required'
      }
    )
    .default([defaultSpace])
});

export type AuthfishConfig = z.infer<typeof configSchema>;
export type AuthfishConfigKeys = DeepKeys<AuthfishConfig>;

export const configKeysRequiringRestart: AuthfishConfigKeys[] = ['port', 'https'];
