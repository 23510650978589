import get from 'lodash/get';
import type { AuthfishConfig, AuthfishConfigKeys } from 'shared/authfishConfig';

export function getConfigChanges(
  config1: AuthfishConfig,
  config2: AuthfishConfig,
  watchedKeys: AuthfishConfigKeys[]
) {
  return watchedKeys.filter(
    (key) => getConfigString(config1, key) !== getConfigString(config2, key)
  );
}

function getConfigString(config: AuthfishConfig, key: AuthfishConfigKeys) {
  console.log('config', get(config, key));
  return JSON.stringify(get(config, key));
}
