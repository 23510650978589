import React from 'react';
import { Flex, Layout, theme, Typography } from 'antd';

import { BrandingProps } from '../../types/afConfig';
import defaultBranding from '../../lib/defaultBranding';
import AppPageHeader from './AppPageHeader';
import PageHeader from './PageHeader';

import styles from './WelcomePage.module.scss';
import Logo from './Logo';

interface welcomePageProps {
  branding?: BrandingProps;
  children: React.ReactNode;
  pageTitle: string;
  pageSubTitle?: string;
  showLogo?: boolean;
}

const WelcomePage = ({
  branding,
  children,
  pageTitle,
  pageSubTitle,
  showLogo
}: welcomePageProps) => {
  /* wraps the login/onboarding pages, could use for anything generic */
  const { token } = theme.useToken();
  return (
    <>
      <PageHeader pageTitle={pageTitle} branding={branding} />
      <Layout className={styles.main}>
        <Layout.Header className={styles.header}>
          <AppPageHeader branding={branding} />
        </Layout.Header>
        <Layout.Content className={styles.content}>
          <Flex className={styles.formContainer} justify="center" vertical align="center" gap={16}>
            {showLogo && <Logo branding={branding} className={styles.logo} />}
            {pageSubTitle && (
              <Typography.Title level={2} className={styles.title}>
                {pageSubTitle}
              </Typography.Title>
            )}
            {children}
          </Flex>
        </Layout.Content>

        <Layout.Footer style={{ background: token.colorWhite }}>
          <div className={styles.footer}>{branding?.footer || defaultBranding.footer}</div>
        </Layout.Footer>
      </Layout>
    </>
  );
};

export default WelcomePage;
