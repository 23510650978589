import React from 'react';

import {
  AuthfishConfig,
  defaultLogoutPath,
  defaultGroupsParam,
  defaultOIDCScopes,
  defaultRoleParam,
  defaultTokenEndpointAuthMethods
} from 'shared/authfishConfig';
import { defaultOIDCCallbackURL, fullLoginRoute, fullSAMLLogoutURL } from 'shared/routes';
import { FormField } from '../../../types/FormFields';

function formItemInstructions(item: FormField, config?: AuthfishConfig) {
  const [local, oidc, saml] = ['local', 'oidc', 'saml'].map((p) => `auth.${p}`);
  let title = '';
  let extraInfo: string | React.ReactNode = '';
  const samlEntryPoint = config?.auth?.saml?.entry_point;
  const samlDescriptorURL = samlEntryPoint ? `${samlEntryPoint}/descriptor` : undefined;
  const getItemKey = (item: FormField): string =>
    item.parentSchema && !item.key.startsWith(item.parentSchema.key)
      ? `${getItemKey(item.parentSchema)}.${item.key}`
      : item.key;
  const itemKey = getItemKey(item).replaceAll(/\.\d+\./g, '.');
  switch (itemKey) {
    case 'site_url':
      title = 'The public URL of the application.';
      extraInfo = (
        <>
          This URL will be used as the default for authentication provider redirects.
          Example: {window.location.origin}
        </>
      );
      break;
    case 'public_routes':
      title = 'Routes that are publicly available without authentication.';
      extraInfo = (
        <>
          Example: <b>/public/</b>
        </>
      );
      break;
    case `${local}.users.roles`:
      title = 'User roles that can be assigned to spaces to give application permissions';
      break;
    case `${local}.users.system_role`:
      title = 'Access level for system settings';
      extraInfo = (
        <>
          <b>user</b>: normal user, cannot access the system settings
          <br />
          <b>system</b>: can access and modify the system settings
        </>
      );
      break;
    case `${oidc}.issuer`:
      title =
        "URL of the OIDC provider's authorization server. All the paths below are relative to this URL.";
      extraInfo = (
        <>
          For Keycloak that's a Realm URL.
          <br />
          <br />
          Example: <b>http://localhost:8080/realms/YourRealm</b>
        </>
      );
      break;
    case `${oidc}.callbackURL`:
      title = 'Full Callback URL for the OIDC provider.';
      extraInfo = (
        <>
          Default: <b>{defaultOIDCCallbackURL(config)}</b>
          <br />
          <br />
          For Keycloak you should register this URL in your Client Settings. Access Settings:{' '}
          <b>
            <u>Authorized Redirect URIs</u>
          </b>
          .
        </>
      );
      break;
    case `${oidc}.logoutPath`:
      title = 'Path for the logout endpoint.';
      extraInfo = (
        <>
          Default: {defaultLogoutPath}
          <br />
          <br />
          <b>IMPORTANT:</b>
          <br />
          For Keycloak please also register the following URL: <b>{fullLoginRoute(config)}</b> in
          your Client Settings. Access Settings:{' '}
          <b>
            <u>Valid post logout redirect URIs</u>
          </b>
          .
        </>
      );
      break;
    case `${oidc}.scope`:
      title = 'OpenID Connect Scopes';
      extraInfo = (
        <>
          Default: {defaultOIDCScopes.join(', ')}
          <br />
          <br />
          <b>IMPORTANT:</b>
          <br />
          Please be careful with custom scopes! Adding non-existing ones may cause various errors,
          for instance redirecting to the login URL again after the actual authorization.
        </>
      );
      break;
    case `${oidc}.groupsParameter`:
      title = `Groups/Roles Parameter name. Default: ${defaultGroupsParam}`;
      extraInfo = (
        <>
          <b>IMPORTANT:</b>
          <br />
          You'll have to add the roles mapper to the <b>ID Token</b>
          <br />
          <br />
          For Keycloak please go to Client Scopes: <u>profile</u> and
          <br />- choose the "<b>Mappers</b>" {'=>'} "Add mapper" {'=>'} "From predefined..."
          <br />- add the "<u>groups</u>" mapping there
        </>
      );
      break;
    case `${oidc}.authMethod`:
      title = `Available token endpoint auth methods.`;
      extraInfo = (
        <>
          Default: {defaultTokenEndpointAuthMethods}
          <br />
          <br />
          <b>client_secret_basic</b>: The `client_id` and `client_secret` are sent using the
          Authorization header, as specified in. Before sending, the `client_id` and `client_secret`
          are encoded.
          <br />
          <br />
          <b>client_secret_post</b>: The `client_id` and `client_secret` are included in the request
          body. In this method, the `client_id` and `client_secret` are not encoded before being
          sent. Choose this method when the OpenID Connect provider, such as lemonLDAP, cannot
          decode the encoded values.
        </>
      );
      break;
    case `${saml}.entry_point`:
      title = 'Identity Provider entrypoint URL.';
      extraInfo = (
        <>
          Required to be spec-compliant when the request is signed.
          <br />
          <br />
          Example: <b>https://keycloak.server/realms/yourRealm/protocol/saml</b>
          <br />
          <br />
        </>
      );
      break;
    case `${saml}.issuer`:
      title = 'Issuer string to supply to Identity Provider.';
      extraInfo = "For Keycloak that's a Client ID.";
      break;
    case `${saml}.cert`:
      title = 'Signing Certificate for Entity';
      extraInfo = (
        <>
          Could be found in SAML 2.0 Identity Provider Metadata as{' '}
          <b>
            {'<'}ds:X509Certificate{'>'}
          </b>
          <br />
          <br />
          For Keycloak the URL is{' '}
          <b>
            <u>{samlDescriptorURL}</u>
          </b>
          <br />
          <br />
          Example: long string like <b>MIICizCCAfQCQCET8tKaMc0BMjANBgkqh ... g=</b>
        </>
      );
      break;
    case `${saml}.logout_callback_url`:
      title = 'Full Callback URL for the SAML provider.';
      extraInfo = (
        <>
          Example: <b>{fullSAMLLogoutURL(config)}</b>
        </>
      );
      break;
    case `${saml}.groupsParameter`:
      title = `Name of the SAML attribute to extract the group/roles from. Default: ${defaultRoleParam}`;
      extraInfo = (
        <>
          <b>IMPORTANT:</b>
          <br />
          Please make sure you've enabled <b>Single Role Attribute</b> (Single Role Attribute
          Mapping, Roles as Claims, etc depending on the IdP) in your SAML Identity Provider.
          <br />
          <br />
          For Keycloak please go to Client Scopes: <u>role_list</u> and
          <br />- choose the "<b>Mappers</b>" {'=>'} choose "role_list" again
          <br />- turn on "<u>Single Role Attribute</u>"
        </>
      );
      break;
    default:
      return;
  }
  return (
    <span>
      {title}
      {extraInfo && (
        <>
          <br />
          <br />
          {extraInfo}
        </>
      )}
    </span>
  );
}

export default formItemInstructions;
